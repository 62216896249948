import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithub, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import menuIcon from '../assets/menu-24px.svg';
import bgImage from '../assets/background-img.jpg';
import './AppHeader.css';
import './Background.css';

const Background = () => {
  return (
    <div>
      <div className='AppHeaderContainer'>
        <h2>MITCHELL EASTMAN</h2>
        <Menu options={[
          <a href="https://github.com/mitchell97" target="_blank" rel="noreferrer">
            <FontAwesomeIcon icon={faGithub} className="BrandIcon" />
          </a>,
          <a href="https://www.linkedin.com/in/mitchell-eastman" target="_blank" rel="noreferrer">
            <FontAwesomeIcon icon={faLinkedin} className="BrandIcon" />
          </a>,
          <a href="mailto:mitchell_eastman@yahoo.com">
            <FontAwesomeIcon icon={faPaperPlane} className="BrandIcon" />
          </a>,
          // <a href="#Home" key="menuHomeKey"><h5>HOME</h5></a>,
          // <a href="#AboutMe" key="menuAboutMeKey"><h5>ABOUT ME</h5></a>,
          // <a href="#Timeline" key="menuTimelineKey"><h5>TIMELINE</h5></a>,
          // <a href="#Projects" key="menuProjectsKey"><h5>PROJECTS</h5></a>,
        ]} />
      </div>
      <div>
        <div className='BackgroundImage'>
          <img src={bgImage} className='ImageStyle' alt='background' />
          <h1>Software Engineer</h1>
        </div>
      </div>
    </div>
  );
}

export default Background;


class Menu extends React.Component {
  constructor(props) {
    super(props);
    this.state = { open: false, width: window.innerWidth, height: window.innerHeight, headerHeight: 70 };
    this.handleClick = this.handleClick.bind(this);
  }

  updateDimensions = () => {
    this.setState({
      width: window.innerWidth,
      height: window.innerHeight,
      headerHeight: 70
    });
  };

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  handleClick() {
    this.setState(state => ({
      open: !state.open
    }));
  }

  render() {
    let optionCounter = 0;
    if (this.state.width <= 1100) {
      return this.state.open ?
        <div >
          <img src={menuIcon} onClick={this.handleClick} alt='menu' />
          <div className="hamburger" style={{ top: `${this.state.headerHeight}px` }}>
            {this.props.options.map((option) => <div key={`hamburgerKeyOption${optionCounter++}`} onClick={this.handleClick}>{option}</div>)}
          </div>
        </div>
        :
        <img src={menuIcon} onClick={this.handleClick} alt='menu' />;
    }
    return (
      <div className="collection">
        {this.props.options}
      </div>
    );
  }
}
